import React from 'react';
import { navigate } from 'gatsby';
import {
  Section,
  Heading,
  Box,
  GridWrapper,
  Paragraph
} from '@thepuzzlers/pieces';

// Local components
import { ImageWithWrappedList, PrimaryButton } from 'components';

import { convertApiHtmlText } from 'graphqlHooks/helper';

const styles = [
  {
    wrapperSx: {
      gridColumn: [
        '1 / span 12',
        '1 / span 10',
        '3 / span 15',
        '2 / span 23',
        '1 / span 23',
        '2 / span 20'
      ],
      gridRow: [null, null, null, null, '2 / span 1']
    },
    textSx: {
      gridColumn: [
        '1 / span 11',
        '1 / span 8',
        '1 / span 13',
        '13 / span 11',
        '13 / span 9',
        '12 / span 7'
      ],
      alignSelf: [null, null, null, null, 'end', 'end'],
      mb: [null, null, null, null, 48, 48]
    }
  },
  {
    wrapperSx: {
      gridColumn: [
        '1 / span 11',
        '1 / span 10',
        '6 / span 17',
        '1 / span 24',
        '3 / span 20',
        '4 / span 19'
      ]
    },
    imageGridColumn: [
      '1 / span 10',
      '1 / span 8',
      '1 / span 13',
      '16 / span 9', //custom
      '13 / span 8',
      '12 / span 8'
    ],
    textSx: {
      gridColumn: [
        '1 / span 11',
        '1 / span 8',
        '1 / span 13',
        '1 / span 11',
        '1 / span 9',
        '1 / span 8'
      ],
      alignSelf: [null, null, null, null, 'end', 'end'],
      mb: [null, null, null, null, 48, 48]
    }
  },
  {
    wrapperSx: {
      gridColumn: [
        '1 / span 11',
        '1 / span 10',
        '1 / span 17',
        '1 / span 22',
        '2 / span 21',
        '3 / span 17'
      ]
    },
    textSx: {
      gridColumn: [
        '1 / span 11',
        '1 / span 9',
        '1 / span 13',
        '12 / span 11',
        '11 / span 9',
        '11 / span 7'
      ],
      alignSelf: [null, null, null, null, 'end', 'end'],
      mb: [null, null, null, null, 48, 48]
    }
  }
];

export const Examples = ({
  staticData: { images },
  data: {
    caption,
    contact_button_text,
    contact_caption,
    contact_headline,
    headline,
    image_descriptions,
    note
  }
}) => (
  <Section>
    <Heading
      sx={{
        gridColumn: [
          '1 / span 11',
          null,
          '1 / span 21',
          '1 / span 16',
          '1 / span 15',
          '2 / span 14'
        ],
        marginBottom: [
          '10.6rem',
          '20.5rem',
          '11.2rem',
          '8.0rem',
          '11.2rem',
          '15.6rem'
        ],
        fontFamily: 'primary.normal',
        lineHeight: 1.25,
        fontSize: ['3rem', '3.8rem', '4.4rem', '2.8rem', '4.2rem', '4.8rem'],
        '& > span': {
          fontFamily: 'primary.bold'
        }
      }}
      as="h2"
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(headline.html) }}
    />
    <TextContent
      text={caption}
      sx={{
        gridColumn: [
          '1 / span 11',
          '2 / span 10',
          '10 / span 14',
          '2 / span 13',
          '13 / span 10',
          '13 / span 9'
        ],
        gridRow: ['2 / span 1', null, null, null, '2 / span 1', null],
        marginBottom: [
          '3.2rem',
          '8.7rem',
          '7.6rem',
          '6.4rem',
          '16.6rem',
          '20.6rem'
        ],
        paddingLeft: [
          '2.4rem',
          '3.9rem',
          '2.5rem',
          '2.9rem',
          '4.2rem',
          '5.4rem'
        ],
        alignSelf: 'start',
        borderLeft: ({ colors }) => `solid ${colors.secondary}`,
        borderWidth: [3, 5, 5, 3, 4, 4]
      }}
    />
    {image_descriptions.map((data, i) => {
      return (
        <ImageWithWrappedList
          key={data.data.title.html}
          wrapperSx={styles[i].wrapperSx}
          textSx={styles[i].textSx}
          imageGridColumn={styles[i].imageGridColumn}
          image={{
            image: images[i].src,
            alt: images[i].alt,
            title: convertApiHtmlText(data.data.title.html)
          }}
          data={data.data}
          index={i}
          totalData={images.length}
        />
      );
    })}

    <Paragraph
      className="footer-note"
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(note.html) }}
      sx={{
        gridColumn: [
          '1 / span 11',
          '1 / span 8',
          '1 / span 15',
          '12 / span 12',
          '12 / span 9',
          '13 / span 9'
        ],
        margin: [
          '6.4rem 0 11.2rem 0',
          '10.0rem 0 13.4rem 0',
          '6.5rem 0 24.6rem 0',
          '5.2rem 0 10.0rem 0',
          '0 0 19.2rem 0',
          '1.3rem 0 22.4rem 0'
        ],
        '& > span': {
          color: 'secondary'
        },
        fontFamily: 'body.bold',
        lineHeight: 1.75,
        fontSize: ['1.6rem', '1.8rem', '1.8rem', '1.6rem', '1.8rem', '1.8rem']
      }}
    />
    <Heading
      as="h3"
      dangerouslySetInnerHTML={{
        __html: convertApiHtmlText(contact_headline.html)
      }}
      sx={{
        gridColumn: [
          '1 / span 10',
          null,
          '5 / span 15',
          '4 / span 13',
          '2 / span 10',
          '3 / span 10'
        ],
        gridRow: [null, null, null, null, '6 / span 1', null],
        marginBottom: ['2.8rem', '6.4rem', '4.8rem', null, '9rem', null],
        fontFamily: 'primary.normal',
        lineHeight: 1.5,
        fontSize: ['2.2rem', '3rem', '3rem', '2.2rem', '3rem', '3.6rem'],
        '& > span': {
          fontFamily: 'primary.bold'
        }
      }}
    />
    <SectionFooter
      contactCaption={contact_caption}
      buttonText={contact_button_text}
    />
  </Section>
);

//==============COMPONENTS=========================
const TextContent = ({ text, sx, headingSx }) => {
  return (
    <Box
      sx={{
        ...sx
      }}>
      <Heading
        sx={{
          fontFamily: 'primary.normal',
          lineHeight: 1.5,
          fontSize: ['2.2rem', '3rem', '3rem', '2.2rem', '3rem', '3.6rem'],
          '& > span': {
            fontFamily: 'primary.bold'
          },
          ...headingSx
        }}
        as="h3"
        dangerouslySetInnerHTML={{ __html: convertApiHtmlText(text.html) }}
      />
    </Box>
  );
};

const SectionFooter = ({ contactCaption, buttonText }) => {
  return (
    <GridWrapper
      variant="inside.autoColumns"
      sx={{
        gridColumn: [
          '1 / span 12',
          '3 / span 9',
          '9 / span 15',
          '8 / span 15',
          '14 / span 10',
          '15 / span 9'
        ],
        gridRow: [null, null, null, null, '6 / span 1', null]
      }}>
      <Box
        sx={{
          gridColumn: [
            '1 / span 12',
            '1 / span 9',
            '1 / span 15',
            '1 / span 15',
            '1 / span 10',
            '1 / span 9'
          ],
          paddingLeft: ['2.4rem', '3.9rem', '2.5rem', '2.6rem', '3.7rem', null],
          borderLeft: ({ colors }) => `solid ${colors.secondary}`,
          borderWidth: [3, 5, 5, 3, 5, 3]
        }}>
        <Paragraph
          dangerouslySetInnerHTML={{
            __html: convertApiHtmlText(contactCaption.html)
          }}
          sx={{
            marginBottom: ['4.8rem', null, null, '3.2rem', '4.8rem', '4.8rem'],
            fontFamily: 'body.normal',
            lineHeight: 1.75,
            fontSize: [
              '1.6rem',
              '1.8rem',
              '1.8rem',
              '1.6rem',
              '1.8rem',
              '1.8rem'
            ],
            '& > span': {
              fontFamily: 'body.bold'
            }
          }}
        />
        <PrimaryButton onClick={() => navigate('/kontakt/')}>
          {buttonText}
        </PrimaryButton>
      </Box>
    </GridWrapper>
  );
};
