import React from 'react';

// Pieces imports
import { Section, Heading } from '@thepuzzlers/pieces';

// Local components
import { NumberedList } from 'components';
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Steps = ({ data: { headline, step_list } }) => {
  return (
    <Section id="leistungen_steps">
      <Heading
        sx={{
          gridColumn: [
            '1 / span 12',
            '1 / span 11',
            '1 / span 16',
            '2 / span 18',
            '2 / span 17',
            '3 / span 16'
          ],
          lineHeight: [1.25, 1.5, 1.5, 1.5, 1.5, 1.5],
          marginBottom: [
            '15.8rem',
            '8.0rem',
            '10.0rem',
            '5.2rem',
            '7.6rem',
            '7.2rem'
          ],
          fontSize: [
            '2.4rem',
            '3.8rem',
            '4.4rem',
            '2.8rem',
            '4.2rem',
            '4.8rem'
          ],
          fontFamily: 'primary.normal',
          '& > span': {
            fontFamily: 'primary.bold'
          }
        }}
        as="h2"
        dangerouslySetInnerHTML={{ __html: convertApiHtmlText(headline.html) }}
      />
      <NumberedList data={step_list} shortHeadline />
    </Section>
  );
};
