import React from 'react';
import { Section, Heading, Paragraph, FlexWrapper } from '@thepuzzlers/pieces';
import { SmallListItem } from 'components';

// Helper Function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Extras = ({ data: { caption, extras, headline } }) => {
  return (
    <Section id="facts">
      <Headline headline={headline} />
      <Caption caption={caption} />
      <List listItems={extras} />
    </Section>
  );
};

const Headline = ({ headline }) => (
  <Heading
    as="h2"
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(headline.html)
    }}
    sx={{
      gridRow: 1,
      gridColumn: [
        '1/span 12',
        '2/span 9',
        '3/span 16',
        '8/span 10',
        '7/span 12',
        '8/span 10'
      ],
      mb: ['2rem', '3.2rem', '2.4rem', '4.3rem', '5.1rem', '7.3rem'],
      textAlign: ['left', 'left', 'left', 'center', 'center', 'center'],
      fontFamily: 'primary.normal',
      lineHeight: 1.25,
      fontSize: ['3rem', '3.8rem', '4.4rem', '2.8rem', '4.2rem', '4.8re'],
      '& > span': {
        fontFamily: 'primary.bold'
      }
    }}
  />
);

const Caption = ({ caption }) => (
  <Paragraph
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(caption.html)
    }}
    sx={{
      gridRow: 2,
      gridColumn: [
        '1/span 11',
        '2/span 9',
        '3/span 14',
        '1/span 12',
        '3/span 9',
        '4/span 8'
      ],
      mb: ['8rem', '10rem', '4.8rem', 0, 0, 0],
      fontFamily: 'body.normal',
      fontSize: ['1.6rem', '1.8rem', '1.8rem', '1.6rem', '1.8rem', '1.8rem'],
      lineHeight: 1.75,
      '& > span': {
        fontFamily: 'body.bold'
      }
    }}
  />
);

const List = ({ listItems }) => (
  <FlexWrapper
    sx={{
      gridColumn: [
        '1/span 11',
        '2/span 8',
        '13/span 11',
        '15/span 10',
        '14/span 9',
        '15/span 7'
      ],
      flexDirection: 'column'
    }}>
    {listItems.map((item, index) => (
      <SmallListItem
        key={item.text}
        text={item.text}
        sx={{
          marginTop: index !== 0 && [48, 48, 48, 40, 48, 48]
        }}
        variant="bold"
      />
    ))}
  </FlexWrapper>
);
