import React from 'react';

import { Section, Heading, Paragraph } from '@thepuzzlers/pieces';

// Local components
import { SmallListItem } from 'components';

// Assets
import Pattern from 'assets/patterns/pattern-bg.png';

// Helper Function
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Warranty = ({
  data: { description, headline, warranty_list }
}) => {
  return (
    <Section
      id="warranty"
      sx={{
        background: ({ colors }) => `url(${Pattern}), ${colors.primary}`,
        backgroundSize: 'cover',
        pt: ['10rem', '11.9rem', '6.4rem', '7.1rem', '8.6rem', '10.1rem'],
        pb: ['10rem', '12.8rem', '7.6rem', '13.1rem', '10.2rem', '15.5rem']
      }}>
      <HeadlineAndText headline={headline} paragraph={description} />
      <List listItems={warranty_list} />
    </Section>
  );
};

const HeadlineAndText = ({ headline, paragraph }) => (
  <>
    <Heading
      as="h2"
      dangerouslySetInnerHTML={{
        __html: convertApiHtmlText(headline.html)
      }}
      sx={{
        gridColumn: [
          '1/span 11',
          '2/span 9',
          '3/span 13',
          '13/span 12',
          '14/span 10',
          '14/span 8'
        ],
        gridRow: 1,
        mb: ['2rem', '3.2rem', '2.4rem', '2rem', '2.4rem', '3.2rem'],
        color: 'background',
        fontFamily: 'primary.normal',
        fontSize: ['3rem', '3.8rem', '4.4rem', '2.8rem', '4.2rem', '4.8rem'],
        lineHeight: 1.25,
        '& > span': {
          fontFamily: 'primary.bold',
          color: 'secondary'
        }
      }}
    />
    <Paragraph
      dangerouslySetInnerHTML={{
        __html: convertApiHtmlText(paragraph.html)
      }}
      sx={{
        color: 'background',
        gridColumn: [
          '1/span 11',
          '2/span 9',
          '3/span 13',
          '13/span 12',
          '14/span 10',
          '14/span 8'
        ],
        gridRow: [2, 2, 2, '2/span 3', '2/span 3', '2/span 3'],
        mb: [80, 100, 100, 0, 0, 0],
        fontFamily: 'body',
        lineHeight: 1.75,
        fontSize: ['1.6rem', '1.8rem', '1.8rem', '1.6rem', '1.8rem', '1.8rem'],
        '& > span': {
          fontFamily: 'body.bold',
          color: 'secondary'
        }
      }}
    />
  </>
);

const List = ({ listItems }) => {
  const styles = [
    {
      gridRow: [3, 3, 3, 2, 2, 2]
    },
    {
      gridRow: [4, 4, 3, 3, 3, 3]
    },
    {
      gridRow: [5, 5, 4, 4, 4, 4]
    },
    {
      gridRow: [6, 6, 4, 5, 5, 5]
    }
  ];

  return listItems.map((item, index) => (
    <SmallListItem
      color="background"
      key={item.text}
      text={item.text}
      sx={{
        alignSelf: [null, null, null, 'start', 'start', 'start'],
        mt: index !== 0 && [48, 48, 0, 40, 48, 48],
        gridColumn:
          index % 2 === 0
            ? [
                '1/span 11',
                '2/span 8',
                '3/span 9',
                '1/span 10',
                '3/span 9',
                '4/span 7'
              ]
            : [
                '1/span 11',
                '2/span 8',
                '14/span 9',
                '1/span 10',
                '3/span 9',
                '4/span 7'
              ],
        pb: index < 2 ? [0, 0, 79, 0, 0, 0] : null,
        ...styles[index]
      }}
      variant="bold"
    />
  ));
};
