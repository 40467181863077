import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';

// Local Components
import {
  useLeistungenPageData,
  Steps,
  Examples,
  Warranty,
  Extras,
  Furniture
} from 'sections/leistungenPage';
import { Spacer } from 'components';

// Section Components
import { HeaderWithNumber } from 'components/section';

// Assets
import thumbnail from 'assets/seo/thumbnail.png';
import { useKisLeistungenPageData } from 'graphqlHooks';

const Leistungen = () => {
  const { seo, header, examples, furniture } = useLeistungenPageData();
  const {
    examples: cmsExamples,
    extras: cmsExtras,
    furniture: cmsFurniture,
    header: cmsHeader,
    steps,
    warranty: cmsWarranty
  } = useKisLeistungenPageData();

  return (
    <>
      <SEO {...seo} />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seo.url} />
        <meta property="og:image" content={thumbnail} />

        {/* Twitter */}
        <meta property="twitter:url" content={seo.url} />
        <meta property="twitter:image" content={thumbnail} />
      </Helmet>
      <HeaderWithNumber staticData={header} data={cmsHeader} />
      <Spacer height={['24rem', '40rem', '40rem', '26rem', '37rem', '42rem']} />
      <Steps data={steps} />
      <Spacer height={['24rem', '40rem', '40rem', '16rem', '24rem', '40rem']} />
      <Examples staticData={examples} data={cmsExamples} />
      <Spacer height={['16rem', '24rem', '24rem', '16rem', '24rem', '24rem']} />
      <Warranty data={cmsWarranty} />
      <Spacer height={['8rem', '16rem', '24rem', '24rem', '24rem', '24rem']} />
      <Extras data={cmsExtras} />
      <Spacer height={['24rem', '24rem', '24rem', '16rem', '24rem', '40rem']} />
      <Furniture staticData={furniture} data={cmsFurniture} />
      <Spacer height={['16rem', '24rem', '24rem', '16rem', '24rem', '24rem']} />
    </>
  );
};

export default injectIntl(Leistungen);
