import { graphql, useStaticQuery } from 'gatsby';

export const useLeistungenPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: leistungenPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          image {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
        examples {
          images {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
        furniture {
          image {
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
            alt
          }
        }
      }
    }
  `);

  return data;
};
