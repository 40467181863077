import React from 'react';

// pieces
import { Section, Heading, Paragraph } from '@thepuzzlers/pieces';

import { ImageContainer } from 'components';

// Helper Funcntion
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Furniture = ({
  staticData: { image },
  data: { caption, headline }
}) => {
  return (
    <Section id="furniture">
      <ImageContainer
        src={image.src}
        imageAlt={image.alt}
        sx={{
          boxShadow: 'elevation700',
          gridColumn: [
            '1/span 12',
            '2/span 12',
            '7/span 17',
            '15/span 10',
            '14/span 11',
            '15/span 9'
          ],
          gridRow: [1, 1, 1, 2, 2, 2],
          mt: [null, null, null, '10%', '-20%', '-35%']
        }}
      />
      <Headline heading={headline} />
      <Text text={caption} />
    </Section>
  );
};

const Headline = ({ heading }) => (
  <Heading
    as="h2"
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(heading.html)
    }}
    sx={{
      gridColumn: [
        '1/span 12',
        '1/span 9',
        '3/span 16',
        '1/span 11',
        '1/span 12',
        '1/span 10'
      ],
      mt: ['10rem', '14.9rem', '10rem', 0, 0, 0],
      mb: ['2rem', '3.2rem', '2.4rem', '2rem', '2.4rem', '3.2rem'],
      gridRow: [2, 2, 2, 1, 1, 1],
      fontFamily: 'primary.normal',
      lineHeight: 1.25,
      '& > span': {
        fontFamily: 'primary.bold'
      },
      fontSize: ['3rem', '3.8rem', '4.4rem', '2.8rem', '4.2rem', '4.8rem']
    }}
  />
);

const Text = ({ text }) => (
  <Paragraph
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(text.html)
    }}
    sx={{
      gridRow: [3, 3, 3, 2, 2, 2],
      gridColumn: [
        '1/span 12',
        '1/span 9',
        '3/span 13',
        '1/span 12',
        '1/span 10',
        '2/span 8'
      ],
      fontFamily: 'body.normal',
      lineHeight: 1.75,
      '& > span': {
        fontFamily: 'body.bold'
      },
      fontSize: ['1.6rem', '1.8rem', '1.8rem', '1.6rem', '1.8rem', '1.8rem']
    }}
  />
);
